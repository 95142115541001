<div class="table-responsive">
   <table class="table table-borderless table-hover table-sm">
      <thead>
         <tr>
            <th scope="col" style="width: 10%">Código</th>
            <th scope="col" style="width: 35%">Descripción</th>
            <th scope="col">Reemplaza a</th>
            <th scope="col" class="text-right">Cajas</th>
            <th scope="col" style="width: 5%" *ngIf="!readonly">Acciones</th>
         </tr>
      </thead>
      <tbody>
         <tr *ngFor="let item of items; trackBy: trackById">
            <td class="align-middle">{{ item.mATERIAL.MATNR | ltrim:'0' }}</td>
            <td class="align-middle">{{ item.mATERIAL.MAKTX }}</td>
            <td class="align-middle">
               <ng-container *ngIf="_cambios.get(item.mATERIAL.MATNR) as cambio">
                  {{ cambio.original.MATNR | ltrim:'0' }}
               </ng-container>
            </td>
            <td class="align-middle text-right">{{ item.TARGET_QTY }}</td>
            <td class="align-middle" *ngIf="!readonly">
               <button type="button" class="btn btn-warning btn-sm" (click)="cambiar(item)">
                  Cambiar
               </button>
            </td>
         </tr>
      </tbody>
   </table>
</div>