import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAccion } from '../condiciones.models';

@Component({
    selector: 'app-cc-acciones-entrega',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <table class="table">
        <thead>
            <tr *ngIf="titulo">
                <th class="text-center" scope="colgroup" colspan="4">
                    <span class="card-title">{{ titulo }}</span>
                </th>
            </tr>
            <tr>
                <th>Categoría - Subcategoría - Material
                <th>Bonificación</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of acciones">
                <td>{{ describirLinea(a) }}</td>
                <td>{{ describirBonificacion(a) }}</td>
            </tr>
        </tbody>
    </table>
    `
})
export class CondicionComercialAccionesEntregaComponent {
    @Input() titulo: string;
    @Input() acciones: IAccion[];

    describirLinea(a: IAccion) {
        if (a.regla_predefinida_id) {
            return a.regla_predefinida_id == "4"
                ? '(primero coincidente con regla)'
                : a.mATNR?.MAKTX;
        }

        const placeholder = 'Indistinto';

        const categoria = a.sPART?.VTEXT ?? placeholder;
        const subcategoria = a.kONDM?.VTEXT ?? placeholder;
        const material = a.mATNR?.MAKTX ?? placeholder;

        return `${categoria} - ${subcategoria} - ${material}`;
    }

    describirBonificacion(a: IAccion) {
        if (a.regla_predefinida_id == null) {
            return `${a.valor}% de CJ a facturar`;
        }

        if (a.regla_predefinida_id == '4') {
            return `${a.valor}% de bultos totales de la regla`;
        }

        return `${a.valor} CJ cada $${a.monto_base} en ${a.reglaPredefinida.nombre}`;
    }
}