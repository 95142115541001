import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

enum EstadoLogistico {
    ANULADO = 'ANULADO',
    DISTRIBUCION = 'DISTRIBUCION',
    ENTREGADO = 'ENTREGADO',
    PENDIENTE = 'PENDIENTE',
    PREPARACION = 'PREPARACION',
    INDETERMINADO = 'INDETERMINADO',
}

@Component({
    selector: 'app-badge-despacho',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <span *ngIf="config.get(estado) as c" class="small badge badge-{{ c.type }}">{{ c.label }}</span>
    `
})
export class BadgeDespachoComponent {
    readonly config = new Map([
        [ EstadoLogistico.PENDIENTE, { label: 'Pendiente', type: 'secondary' } ],
        [ EstadoLogistico.PREPARACION, { label: 'En preparación', type: 'warning' } ],
        [ EstadoLogistico.DISTRIBUCION, { label: 'En distribución', type: 'primary' } ],
        [ EstadoLogistico.ENTREGADO, { label: 'Entregado', type: 'success' } ],
        [ EstadoLogistico.ANULADO, { label: 'Anulado', type: 'danger' } ],
        [ EstadoLogistico.INDETERMINADO, { label: 'Indeterminado', type: 'secondary' } ],
    ])

    @Input() estado: EstadoLogistico;
}
