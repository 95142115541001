import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { distinct, distinctUntilChanged, filter, first, map, mapTo, tap } from "rxjs/operators";
import { MaterialesService } from "../materiales.service";
import { ifNotNullOrUndefined } from "../utils/operators";
import { Restrictors } from "../utils/validators/restrictors";
import { ListaPreciosService } from "./lista-precios.service";
import { MaterialPrVtas } from "./models/ListaPrecios.model";
import { StockService } from "./stock/stock.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "[app-lista-materiales-item]",
  templateUrl: "./lista-materiales-item.component.html",
  styles: [`
    td, th {
      padding: 0.35rem 0.2rem;
    }

    .text-decoration-line-through {
      text-decoration-line: line-through !important;
    }
  `],
})
export class ListaMaterialesItemComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private item$: BehaviorSubject<MaterialPrVtas> = new BehaviorSubject(null);
  private sinDtoAdi: boolean;
  private _edit: boolean;

  readonly isProduction = environment.production;
  
  public get edit(): boolean {
    return this._edit;
  }

  @Input()
  public set edit(value: boolean) {
    this._edit = value;

    const bonifica = this.itemForm.get('bonifica_canal');
    if (!value) {
      bonifica.disable();  
    } else {
      bonifica.enable();
    }
  }
  @Input() estado: number;
  @Input() set item(item: MaterialPrVtas) {
    this.item$.next(item);
  }

  get item() {
    return this.item$.getValue();
  }

  @Output() itemChange: EventEmitter<MaterialPrVtas> = new EventEmitter();

  @Output() change = this.itemChange.pipe(mapTo(true));

  @Input() dirty: boolean;
  @Output() dirtyChange: EventEmitter<boolean> = new EventEmitter();

  // @Input() centro: any;

  @Input() currency: string;

  private tiposDescuento$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  @Input() set tiposDescuento(tipos: any[]) {
    this.tiposDescuento$.next(tipos);
  }

  get tiposDescuento() {
    return this.tiposDescuento$.getValue();
  }

  private cantidadValidator = (form: AbstractControl) => {
    if (!this.item) {
      return null;
    }
    var um = form.get("UNIDAD").value;
    var cant = form.get("CANTIDAD").value;
    return !this.lpService.cantidadEsValida(this.item, um, cant)
      ? { CANTIDAD: { value: `La cantidad ingresada es inválida` } }
      : null;
  };

  ajusteSaldo: number;

  palletCompletado: EventEmitter<void> = new EventEmitter();

  itemForm = new FormGroup(
    {
      UNIDAD: new FormControl("", [Validators.required]),
      CANTIDAD: new FormControl(0, [Restrictors.integer]),
      CANTIDAD_OLD: new FormControl(0, [Restrictors.integer]),
      CONDITION: new FormControl("", [Validators.required]),
      DESCUENTO: new FormControl(""),
      bonifica_canal: new FormControl(false),
    },
    [this.cantidadValidator]
  );

  get CANTIDAD(): FormControl {
    return this.itemForm.get("CANTIDAD") as FormControl;
  }

  get UNIDAD(): FormControl {
    return this.itemForm.get("UNIDAD") as FormControl;
  }

  get isPrecioFijo() {
    let { value } = this.itemForm;
    return value.CONDITION && value.CONDITION.COND_TYPE === "PR00";
  }

  get tieneDisplay() {
    return this.matService.puedeCalcularDisplay(this.item.mATNR);
  }

  get editaCantidad() {
    return !this.sinStockTag()
      && !this.suspendidoTag()
      && this.tieneDisplay;
  }

  esItemIncluidoEnCombo() {
    return this.item.COND_TYPE == "COMB";
  }

  sinStockTag() {
    if (!this.item.mATNR.tags) {
      return false;
    }
    let res = this.item.mATNR.tags.filter(
      (elem) => elem.nombre == "Sin stock para venta"
    );
    return (res.length > 0);
  }

  suspendidoTag() {
    if (!this.item.mATNR.tags) {
      return false;
    }
    let res = this.item.mATNR.tags.filter(
      (elem) => elem.nombre == "Suspendido"
    );
    return (res.length > 0);
  }

  validarCantidad(event) {
    console.log(event);
  }

  // cantHabilitadas() {
  //   let can = Object.assign({}, this.CANTIDAD);
  //   can.value = 0;
  //   let arr = [can, this.CANTIDAD];
  //   return arr;
  // }

  habilitarDtoAdicional() {
    if (!this.item.mATNR.tags) {
      return false;
    }
    let res = this.item.mATNR.tags.filter(
      (elem) => elem.nombre == "Sin Dto. Adicional"
    );
    return !((res.length > 0) || this.item.KBETR == 0);
  }

  unidadesMedida$ = this.item$.pipe(
    ifNotNullOrUndefined(),
    map((item) =>
      item.mATNR.materialUmconvs.filter(
        (i) => i.KMEIN !== "PAL" && i.habilitado && i.kMEIN.habilitado
      )
    )
  );

  constructor(
    public lpService: ListaPreciosService,
    public matService: MaterialesService,
    public stockService: StockService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([
        this.tiposDescuento$.pipe(
          ifNotNullOrUndefined(),
          filter((t) => t.length > 0),
          distinct()
        ),
        this.item$,
      ]).subscribe(([tipos, item]) => {
        if (item) {
          let condControl = this.itemForm.get("CONDITION");
          condControl.reset(tipos.find((c) => c.COND_TYPE === item.COND_TYPE));
        }
      })
    );

    this.subscriptions.push(
      this.tiposDescuento$
        .pipe(ifNotNullOrUndefined(), first())
        .subscribe(() => {
          this.subscriptions.push(
            this.itemForm
              .get("CONDITION")
              .valueChanges.pipe(
                distinctUntilChanged(),
                tap(() => {
                  this.itemForm.get("DESCUENTO").reset();
                }),
                ifNotNullOrUndefined(),
                map((condition) => condition.COND_TYPE)
              )
              .subscribe((COND_TYPE) => {
                let descuentoControl = this.itemForm.get("DESCUENTO");
                if (COND_TYPE === "PR00") {
                  descuentoControl.setValidators([Restrictors.decimal(2)]);
                } else {
                  descuentoControl.setValidators([
                    Restrictors.max(99),
                    Restrictors.decimal(2),
                  ]);
                }
                descuentoControl.updateValueAndValidity();
              })
          );

          /* this.subscriptions.push(
                        this.itemForm.get('CANTIDAD').valueChanges
                            .subscribe(cantidad => {
                                let control = this.itemForm.get('CONDITION');
                                if (cantidad > 0) {
                                    control.enable();
                                } else {
                                    control.disable();
                                }
                            })
                    ); */

          this.subscriptions.push(
            this.itemForm.valueChanges.subscribe((values) => {
              let item = Object.assign(this.item, values);
              this.dirtyChange.emit(this.itemForm.dirty);
              // item.UNIDAD = values.UNIDAD && values.UNIDAD.KMEIN;
              if (values.CONDITION) {
                item.COND_TYPE = values.CONDITION.COND_TYPE;
              }
              this.itemChange.emit(item);
            })
          );
          
          this.subscriptions.push(
            this.item$
              .pipe(
                map(({ CANTIDAD, UNIDAD, DESCUENTO, CANTIDAD_OLD, bonifica_canal }) => ({
                  CANTIDAD,
                  UNIDAD,
                  DESCUENTO,
                  CANTIDAD_OLD,
                  bonifica_canal,
                }))
              )
              .subscribe((item) => {
                // this.unidadesMedida = item.mATNR.materialUmconvs;
                this.itemForm.reset({ ...this.itemForm.value, ...item });
              })
          );
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getMostrarPeso() {
    return true; // (this.item.CANTIDAD > 0 && this.item.KBETR > 0)
  }

  getMostrarUnidadMedida() {
    return (this.item.KBETR > 0)
  }

  getMostrarUNporCJ() {
    return (this.item.KBETR > 0)
  }

  getDescuento() {
    return this.lpService.getDescuentoAdicional(this.item);
  }

  getDescuentoCombos() {
    return this.lpService.getDescuentoCombos(this.item);
  }

  getImporte() {
    return this.lpService.getImporteItem(this.item, true);
  }

  getDisplay() {
    return this.matService.calcularDisplay(this.item.mATNR);
  }

  getEtiquetas() {
    let tags = this.item.mATNR.tags.map((tag) => {
      return "<span class='badge badge-success'>" + tag.nombre + "</span>";
    });

    return tags;
  }

  getKilos() {
    return this.lpService.getKilos(this.item);
  }

  get tipoDescuentoDescription() {
    var condition =
      this.tiposDescuento && this.item.COND_TYPE
        ? this.tiposDescuento.find((i) => i.COND_TYPE == this.item.COND_TYPE)
        : null;
    return condition && condition.VTEXT;
  }

  registraCajasXPallet() {
    return this.matService.registraCajasXPallet(this.item.mATNR);
  }

  getPalletsCompletos() {
    return this.lpService.getPalletsCompletos(this.item);
  }

  getCajasParaCompletar() {
    return this.lpService.getCajasParaCompletar(this.item);
  }
}
