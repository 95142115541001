<ng-container *ngIf="pedido">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-actions"></div>
        <div class="page-title-icon">
          <img
            class="icon"
            *ngIf="selCliente?.avatar"
            [src]="selCliente?.avatar"
          />
          <i *ngIf="!selCliente?.avatar" class="icon pe-7s-id"></i>
        </div>
        <div>
          {{ selCliente?.NAME }} {{ selCliente.NAME2 }}
          <div class="page-title-subheading">
            {{ selCliente?.KUNNR }}
          </div>
        </div>
      </div>
      <div class="page-title-actions">
        <h4 class="text-nowrap">
          Pedido # {{ pedido?.id }}
          <ng-container *ngIf="pedido.SALESDOCUMENTIN">
            (SAP: {{ pedido.SALESDOCUMENTIN | ltrim:'0' }})
          </ng-container>
        </h4>
      </div>
      <div class="page-title-actions">
        <div class="row">
          <div class="col-md-6">
            <div><b>Fecha</b></div>
            <div>{{ pedido?.DOC_DATE | date: "dd/MM/yyyy" }}</div>
          </div>
          <div class="col-md-6">
            <div><b>Estado</b></div>
            <div style="white-space: nowrap">
              <app-badge-estado
                [orderHeader]="pedido"
                [textOnly]="true"
                [fontColor]="495057"
              ></app-badge-estado>
            </div>
          </div>
        </div>
      </div>
      <div class="page-title-actions" style="margin-left: 5rem">
        <div class="row">
          <div class="col-md-4">
            <div><b>Aprob.Crédito</b></div>
            <div style="white-space: nowrap">{{ pedido?.apr_cred }}</div>
          </div>
        </div>
      </div>
      <div class="page-title-actions" *ngIf="puedeEditar$|async">
        <mat-slide-toggle [formControl]="onEditControl">Edición</mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="main-card card">
    <div class="card-body pb-0">
      <div class="form-row">
        <div class="col-md-12">
          <app-pedido-header
            [form]="formPedido"
            [vendedor]="vendedor"
            [datosComerciales]="selCliente?.clteDatosComs"
            [edit]="onEdit && pedido.estado < 1"
          ></app-pedido-header>
        </div>
      </div>
      <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
        <li ngbNavItem="items">
          <a ngbNavLink>Items</a>
          <ng-template ngbNavContent>
            <ng-container *ngIf="itemSource$ | async as itemSource">
              <ng-container *ngIf="!(loadingListaPrecios | async) && listaMateriales; else cargandoLP">
                <app-lista-materiales
                  [estado]="pedido.estado !== undefined ? pedido.estado : 0"
                  [items]="itemSource"
                  [currency]="pedido.CURRENCY"
                  [edit]="onEdit && pedido.estado < 1"
                  [pesoMinimo]="selDatoCom?.vTWEG?.PESO_MINIMO"
                  [searchTerm]="searchTerm"
                >
                </app-lista-materiales>
              </ng-container>
              <ng-template #cargandoLP>
                <div class="card-body">
                  <p>Cargando Lista de Precios...</p>
                </div>
              </ng-template>
            </ng-container>
          </ng-template>
        </li>
        <!-- <li ngbNavItem="entrega-gratuita" *ngIf="pedido.entregaGratuita && pedido.entregaGratuita?.estado != 90">
          <a ngbNavLink>Entrega Gratuita</a>
          <ng-template ngbNavContent>
            <app-p-entrega-gratuita [entrega]="pedido.entregaGratuita"></app-p-entrega-gratuita>
          </ng-template>
        </li> -->
        <ng-container *ngIf="itemsPendientes$ | async as itemsPendientes">
          <!-- si !pedido.activo, entonces itemsPendientes.length == 0 (porque yo lo forcé) -->
          <li *ngIf="itemsPendientes.length > 0" ngbNavItem="pendientes">
            <a ngbNavLink>Pendientes</a>
            <ng-template ngbNavContent>
              <items-pendientes
                [itemsPendientes]="itemsPendientes"
              ></items-pendientes>
            </ng-template>
          </li>
        </ng-container>
        <li ngbNavItem="bonificada" *ngIf="pedido.entregaGratuita && pedido.entregaGratuita.orderItemsIns.length > 0">
          <a ngbNavLink>Mercadería Bonificada</a>
          <ng-template ngbNavContent>
            <app-pedido-mercaderia-bonificada 
              [items]="pedido.entregaGratuita.orderItemsIns" 
              [cambios]="pedido.cambiosMercaderiaBonificada"
              [readonly]="onEdit || pedido.estado >= 1"
              (cambiar)="cambiarMercaderiaBonificada($event)">
            </app-pedido-mercaderia-bonificada>
          </ng-template>
        </li>
        <li ngbNavItem="log" *ngIf="!onEdit">
          <a ngbNavLink>Bitácora</a>
          <ng-template ngbNavContent>
            <app-timeline
              title="Cambios en el Pedido"
              [events]="eventosPedido"
            ></app-timeline>
          </ng-template>
        </li>
        <li ngbNavItem="docs" *ngIf="!onEdit">
          <a ngbNavLink>Documentos</a>
          <ng-template ngbNavContent>
            <app-files title="Documentos" [files]="documentos"></app-files>
          </ng-template>
        </li>
        <li ngbNavItem="info">
          <a ngbNavLink>Información Adicional</a>
          <ng-template ngbNavContent>
            <app-info-detail
              title="Información Adicional"
              [editInfo]="onEdit && pedido.estado < 1"
              [ultimaObservacion]="pedido.ultimaObservacion"
            ></app-info-detail>
          </ng-template>
        </li>
        <!-- <li ngbNavItem="accsec" *ngIf="!(onEdit)">
          <a ngbNavLink>Acciones Secundarias</a>
          <ng-template ngbNavContent>
            <app-acciones-secundarias
              title="Acciones Secundarias"
              [pedido]="pedido"
              [edit]="onEdit">
            </app-acciones-secundarias>
          </ng-template>
        </li> -->
        <li ngbNavItem="condiciones">
          <a ngbNavLink>Acciones Comerciales</a>
          <ng-template ngbNavContent>
            <app-cc-aplicadas
              [edit]="onEdit"
              [pedido]="pedido"
              [pedidoTieneCambios]="hayCambiosEnItems()"
              [loading]="loadingListaPrecios | async"
            ></app-cc-aplicadas>
            <app-cc-acciones-manuales [editable]="onEdit"></app-cc-acciones-manuales>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</ng-container>
<app-pedidos-footer *ngIf="pedido"
  [pedido]="pedido"
  [onEdit]="onEdit"
  [cliente]="selCliente"
  [datoComercial]="selDatoCom"
  [puedeConfirmar]="puedeConfirmar$|async"
  (onGuardarPedido)="onGuardarPedido()"
  (onConfirmarPedido)="onConfirmarPedido()"
  (onAnularPedido)="onAnularPedido(anularPedido)"
  (onAutorizarPedido)="onAutorizarPedido($event)"
  [interlocutor]="interlocutor"
  (condicionesEntregaChange)="onSetCondicionesEntrega($event)"
  [progressGuardar]="progressGuardar"
  [progressConfirmar]="progressConfirmar"
  (closeCombos)="onCloseCombos($event)"
>
</app-pedidos-footer>

<ng-template #anularPedido let-modal>
  <div id="myModal">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Anular Pedido</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-row">
        <p>Ingrese motivo de anulación</p>
      </div>
      <div class="form-row">
        <textarea
          id="motivo"
          ngbAutofocus
          name="motivo"
          class="form-control"
          [formControl]="motivoAnulacion"
          autofocus
        ></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-success"
        (click)="onAceptarAnulacion()"
      >
        Aceptar
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.close()"
      >
        Cancelar
      </button>
    </div>
  </div>
</ng-template>
