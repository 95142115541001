<div class="card" *ngIf="entrega">
    <div class="card-header">Mercadería Bonificada</div>
    <div class="card-body">
        <div class="form-row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="salesdocumentin">Nº Doc. SAP</label>
                    <input id="salesdocumentin" type="text" class="form-control" [value]="entrega.SALESDOCUMENTIN" readonly />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="estado">Estado</label>
                    <input id="estado" type="text" class="form-control" [value]="entrega.estadoDesc" readonly />
                </div>
            </div>
        </div>
        <hr />
        <div *ngIf="entrega.orderItemsIns as items">
            <ng-container *ngIf="items && items.length; else noData">
                <div class="table-responsive">
                    <table class="align-middle text-truncate mb-2 table table-borderless table-hover table-pedidos" style="overflow: visible;">
                        <thead>
                            <tr>
                                <th scope="col" class="text-left w-25">Código</th>
                                <th scope="col" class="text-left w-50">Descripción</th>
                                <th scope="col" class="text-right" style="width: 10%;">Cantidad</th>
                                <th scope="col" class="text-left" style="width: 15%;">Un.Med</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of items|pagination">
                                <td class="text-left">{{ item.MATERIAL }}</td>
                                <td class="text-left">{{ item.mATERIAL.MAKTX }}</td>
                                <td class="text-right">{{ item.TARGET_QTY }}</td>
                                <td class="text-left">{{ item.TARGET_QU }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row justify-content-end" *ngIf="items && items.length">
                    <div class="col-auto pull-right">
                        <page-selector [collectionSize]="items.length"></page-selector>
                    </div>
                </div>
            </ng-container>
            <ng-template #noData>
                <div class="card-body">
                    <p>No hay productos para entrega bonificada</p>
                </div>
            </ng-template>
        </div>
    </div>
</div>