import { AbstractControl } from "@angular/forms";

export function distinct(path1: string, path2: string, comparator: (a: any, b: any) => boolean = (a, b) => a === b) {
    return function (control: AbstractControl) {
        if (comparator(control.get(path1).value, control.get(path2).value)) {
            return { distinct: {} };
        }

        return null;
    };
}
