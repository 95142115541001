import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { distinctUntilChanged, map, startWith, tap } from "rxjs/operators";
import { distinct } from "src/app/utils/validators/distinct";

type ConfiguracionItem = {
    producto: {
        MATNR: string;
        MAKTX: string;
    };
    cajas: number;
};

@Component({
    selector: 'app-cambio-mercaderia-bonificada-modal',
    templateUrl: './cambio-mercaderia-bonificada-modal.component.html',
})
export class CambioMercaderiaBonificadaModalComponent implements OnInit, OnDestroy {
    private rangeSubscription?: Subscription;

    readonly form = this.fb.group({
        original: this.fb.group({
            producto: [ null ],
            cajas: [ 0 ],
        }),
        reemplazo: this.fb.group({
            producto: [ null, [ Validators.required ]],
            cajas: [ 0, [ Validators.required, Validators.min(1) ]],
        }),
    },
    { 
        validators: [
            distinct('original.producto', 'reemplazo.producto', (a, b) => a?.MATNR === b?.MATNR)
        ],
    });

    readonly original = this.form.get('original') as FormGroup;
    readonly reemplazo = this.form.get('reemplazo') as FormGroup;
    readonly producto = this.form.get('reemplazo.producto') as FormControl;
    readonly cajas = this.form.get('reemplazo.cajas') as FormControl;

    readonly compareWith = <T extends { MATNR: string }>(a: T, b: T) => a.MATNR === b.MATNR;

    productos: Array<{ MATNR: string; MAKTX: string }>;

    constructor(private readonly fb: FormBuilder, readonly modal: NgbActiveModal) {
        this.original.disable();
    }

    ngOnInit(): void {
        const range$ = this.form.valueChanges.pipe(
            map(() => this.form.getRawValue()),
            startWith(this.form.getRawValue()),
            distinctUntilChanged((x, y) => x.reemplazo.producto.MATNR === y.reemplazo.producto.MATNR),
            map(({ original, reemplazo }) => original.producto.MATNR === reemplazo.producto.MATNR
                ? { min: reemplazo.cajas, max: reemplazo.cajas }
                : { min: 0, max: reemplazo.cajas }
            ),
        );

        this.rangeSubscription = range$.subscribe(range => {
            if (this.form.value.reemplazo.cajas < range.min) {
                this.form.patchValue({ reemplazo: { cajas: range.min }}, { emitEvent: false });
            }

            this.setCajasRange(range);
        });
    }

    ngOnDestroy(): void {
        this.rangeSubscription?.unsubscribe();
    }

    guardar() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        const value = this.form.getRawValue();

        this.modal.close(value);
    }

    inicializar({ original, reemplazo, productos }: { original: ConfiguracionItem, reemplazo: ConfiguracionItem, productos: Array<{ MATNR: string, MAKTX: string }> }) {
        const min = reemplazo.producto.MATNR === original.producto.MATNR
            ? reemplazo.cajas
            : 0;

        this.setCajasRange({ min, max: reemplazo.cajas });

        this.form.patchValue({ original, reemplazo });

        this.productos = productos;
    }

    private setCajasRange({ min, max }: { min: number, max: number }) {
        this.cajas.setValidators([ Validators.required, Validators.min(min), Validators.max(max) ]);
        this.cajas.updateValueAndValidity();
    }
}