import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { AccionSecundaria, Condicion, CondicionVariante, CondicionVarianteEntregaGrupo } from "src/app/condiciones-comerciales/condiciones.models";
import { GenericApiClient } from "src/app/utils/api/generic-api-client.service";
import { ClienteDatoComercial } from "../clientes/models/Cliente.model";
import { CondicionVarianteComboGrupo } from "../condiciones-comerciales/condiciones.models";
import { MaterialesService } from "../materiales.service";
import { OrderConditionsIn, OrderItemsIn } from "../pedidos/models/Order.model";
import { DatosComercialesApiClient } from "./datos-comerciales.apiclient.service";
import { ListaPreciosApiClient } from "./lista-precios.apiclient.service";
import { ListaPrecios, MaterialPrVtas } from "./models/ListaPrecios.model";

/**
 * Unidad de medida por defecto
 */
export const LP_UM_DEFAULT = "CJ";

@Injectable({ providedIn: "root" })
export class ListaPreciosService {
  constructor(
    @Inject(ListaPreciosApiClient) private api: GenericApiClient<ListaPrecios>,
    @Inject(DatosComercialesApiClient) private datosComerciales: GenericApiClient<ClienteDatoComercial>,
    private materialesService: MaterialesService
  ) {}
  /*ACA*/
  getList() {
    return this.api.list();
  }

  get(PLTYP: string, params: any = {}) {
    // Para poder optimizar algunas consultas, permito sobrecargar el expand
    // Si no lo sobrecarga, uso el default
    if (!params.expand) {
      params.expand = [
        "materialPrVtas",
        "materialPrVtas.kMEIN",
        "materialPrVtas.mATNR",
        "materialPrVtas.mATNR.materialUmconvs",
        "materialPrVtas.mATNR.materialUmconvs.kMEIN",
        "materialPrVtas.mATNR.stocks.wERKS",
        "materialPrVtas.mATNR.stocks.lGORT",
        "materialPrVtas.mATNR.dWERK",
        "materialPrVtas.materialUmconvs",
        "materialPrVtas.materialUmconvs.kMEIN",
        "materialPrVtas.mATNR.tags",
      ].join(",");
    }
    return this.api.retrieve(PLTYP, params).pipe(
      tap((lista: any) => lista.condicionCanals = [])
    );
  }

  getCondiciones(PLTYP: number|string, orderHeaderInID?: number|string, clienteKunnr?: string) {
    let params: any = {
      expand: [
        "condicionVariantes.condicionVarianteCombos.condicionVarianteComboAccions",
        "condicionVariantes.condicionVarianteCombos.condicionVarianteComboAccions.sPART",
        "condicionVariantes.condicionVarianteCombos.condicionVarianteComboAccions.kONDM",
        "condicionVariantes.condicionVarianteCombos.condicionVarianteComboAccions.mATNR",
        "condicionVariantes.condicionVarianteCombos.condicionVarianteComboGrupos.condicionVarianteComboGrupoMetricas",
        "condicionVariantes.condicionVarianteCombos.condicionVarianteComboGrupos.condicionVarianteComboGrupoReglas",
        "condicionVariantes.condicionVarianteCombos.condicionVarianteComboGrupos.condicionVarianteComboGrupoReglas.sPART",
        "condicionVariantes.condicionVarianteCombos.condicionVarianteComboGrupos.condicionVarianteComboGrupoReglas.kONDM",
        "condicionVariantes.condicionVarianteCombos.condicionVarianteComboGrupos.condicionVarianteComboGrupoReglas.mATNR",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaAccions",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaAccions.sPART",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaAccions.kONDM",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaAccions.mATNR",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaAccions.regla_predefinida_id",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaAccions.reglaPredefinida.id",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaAccions.reglaPredefinida.nombre",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaGrupos.condicionVarianteEntregaGrupoMetricas",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaGrupos.condicionVarianteEntregaGrupoReglas",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaGrupos.condicionVarianteEntregaGrupoReglas.sPART",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaGrupos.condicionVarianteEntregaGrupoReglas.kONDM",
        "condicionVariantes.condicionVarianteEntregas.condicionVarianteEntregaGrupos.condicionVarianteEntregaGrupoReglas.mATNR",
        "condicionVariantes.condicionVarianteAccionsecs",
      ].join(',')
    };

    if (orderHeaderInID) {
      params['order_header_in_id'] = orderHeaderInID;
    }

    if (clienteKunnr) {
      params['cliente_kunnr'] = clienteKunnr;
    }

    return this.api.of(PLTYP).navigate<Condicion>('condiciones').list(params).pipe(
      map(condiciones => condiciones.map<Condicion>(condicion => ({
        ...condicion,
        condicionVariantes: condicion.condicionVariantes.map<CondicionVariante>(variante => ({
          ...variante,
          condicionVarianteAccionsecs: variante.condicionVarianteAccionsecs
            .map(x => new AccionSecundaria(x)),
          condicionVarianteCombos: variante.condicionVarianteCombos
            .filter(x => !!x.condicionVarianteComboGrupos)
            .map(combo => ({
              ...combo,
              condicionVarianteComboGrupos: combo.condicionVarianteComboGrupos
                .map(x => new CondicionVarianteComboGrupo(x))
            })),
          condicionVarianteEntregas: variante.condicionVarianteEntregas
            ?.filter(x => !!x.condicionVarianteEntregaGrupos)
            .map(entrega => ({
              ...entrega,
              condicionVarianteEntregaGrupos: entrega.condicionVarianteEntregaGrupos
                .map(x => new CondicionVarianteEntregaGrupo(x))
            }))
        }))
      }))),
    );
  }

  getPrecios(datoComercialId: string|number): Observable<MaterialPrVtas[]> {
    return this.datosComerciales.of(datoComercialId).navigate<MaterialPrVtas>('precios').list({
      expand: [
        "kMEIN",
        "mATNR",
        "mATNR.materialUmconvs",
        "mATNR.materialUmconvs.kMEIN",
        "mATNR.stocks.wERKS",
        "mATNR.stocks.lGORT",
        "mATNR.dWERK",
        "materialUmconvs",
        "materialUmconvs.kMEIN",
        "mATNR.tags",
      ].join(',')
    });
  }

  updateCantidades(items: MaterialPrVtas[], itemsPedido?: OrderItemsIn[]): MaterialPrVtas[] {
    return items.map((item) => {
      item = Object.assign({}, item, {
        CANTIDAD: 0,
        retorno: 0,
        descuentoPorAcuerdos: 0,
        UNIDAD: this.getUMDefault(item),
        unidad_label: this.getUMDefaultLabel(item),
        CONDITION: null,
        COND_TYPE: "K007",
        DESCUENTO: 0,
        DESCUENTO_COMBO: 0,
      });

      var enPedido =
        itemsPedido &&
        itemsPedido.find((itemPedido) => itemPedido.MATERIAL === item.MATNR);

      if (enPedido) {
        item.CANTIDAD = enPedido.TARGET_QTY;
        item.retorno = enPedido.retorno;
        item.descuentoPorAcuerdos = enPedido.descuentoPorAcuerdos;
        item.UNIDAD = enPedido.TARGET_QU; // item.mATNR.M;
        item.unidad_label = enPedido.tARGETQU.descripcion;
        item.cantidadBonificada = enPedido.cantidadBonificada;

        let orderConditionsIns = enPedido.orderConditionsIns;
        /* orderConditionsIns = orderConditionsIns
                    && orderConditionsIns.filter(i => !['PLIS', 'ZACC', 'Z001', 'Z002', 'COMB'].includes(i.COND_TYPE)); */
        let condition =
          orderConditionsIns &&
          orderConditionsIns.find((x) => x.COND_TYPE == "K007");

        if (condition) {
          item.CONDITION = condition.conditionType;
          item.COND_TYPE = condition.COND_TYPE;
          item.DESCUENTO = condition.COND_VALUE;
        }

        let combo =
          orderConditionsIns &&
          orderConditionsIns.find((x) => x.COND_TYPE == "COMB");
        item.DESCUENTO_COMBO = combo ? combo.COND_VALUE : 0;
      }
      return item;
    });
  }

  private getUMDefault(item: MaterialPrVtas): string {
    // dejo que explote si es undefined o tiene length < 1 para que nos demos cuenta
    let unidades = item.materialUmconvs
      .map((uc) => uc.KMEIN)
      .filter((um) => um !== "PAL");
    return (
      unidades.find((u) => u === LP_UM_DEFAULT) ||
      unidades.find((u) => u === "UN") ||
      unidades[0]
    );
  }

  private getUMDefaultLabel(item: MaterialPrVtas): string {
    // dejo que explote si es undefined o tiene length < 1 para que nos demos cuenta
    let unidades = item.materialUmconvs
      .map((uc) => uc.kMEIN.MEINS)
      .filter((um) => um !== "PAL");
    let sigla =
      unidades.find((u) => u === LP_UM_DEFAULT) ||
      unidades.find((u) => u === "UN") ||
      unidades[0];
    let unidades_label = item.materialUmconvs.find(
      (elem) => elem.kMEIN.MEINS === sigla
    );
    return unidades_label.kMEIN.descripcion;
  }

  getCentroSuministro(item: MaterialPrVtas): string {
    return item.mATNR.DWERK;
  }

  getDescuentoAdicional(item: MaterialPrVtas): number {
    if (item.COND_TYPE === "PR00") {
      // Si es "PR00", se trata de un precio fijo
      return 0;
    }
    let descuento = (item.KBETR ?? 0) * ((item.DESCUENTO || 0) / 100);
    return this.materialesService.getCantidadEnUnidades(item) * descuento;
  }

  getDescuentoCombos(item: MaterialPrVtas): number {
    let descuento = (item.KBETR ?? 0) * ((item.DESCUENTO_COMBO || 0) / 100);
    return this.materialesService.getCantidadEnUnidades(item) * descuento;
  }

  getKilos(item: MaterialPrVtas) {
    const pesoUnidad = this.materialesService.calcularPesoPorUnidad(item.mATNR);
    const display = this.materialesService.calcularDisplay(item.mATNR);
    const cantidad = item.CANTIDAD + (item.cantidadBonificada ?? 0);

    switch (item.UNIDAD) {
      case "UN":
        return cantidad * pesoUnidad;
      case "CJ":
        return cantidad * display * pesoUnidad;
      case "KG":
        return cantidad;
      default:
        return 0;
    }
  }

  getCajas(item: MaterialPrVtas) {
    return Math.floor(
      this.materialesService.getCantidadEnUnidades(item) /
        this.materialesService.calcularDisplay(item.mATNR)
    );
  }

  getImporteItem(
    item: MaterialPrVtas,
    aplicaDescuento: boolean = true
  ): number {
    let precioUnidad = item.COND_TYPE == "PR00" ? item.DESCUENTO : (item.KBETR ?? 0);
    var importe =
      this.materialesService.getCantidadEnUnidades(item) * precioUnidad;

    if (aplicaDescuento) {
      importe -= this.getDescuentoAdicional(item);
      importe -= this.getDescuentoCombos(item);
    }
    return importe;
  }

  /**
   * Obtiene el total pedido (neto, sin descuentos)
   * @param itemsPedido
   */
  getTotalPedido(itemsPedido: MaterialPrVtas[]) {
    return itemsPedido.reduce(
      (subtotal, item) => subtotal + this.getImporteItem(item, false),
      0
    );
  }

  getIVAPedido(itemsPedido: MaterialPrVtas[]) {
    return this.getTotalNeto(itemsPedido) * 0.21;
  }

  getImpuestoInternoPedido(itemsPedido: MaterialPrVtas[]) {
    return 0;
  }

  /**
   * Obtiene el total de la venta sin IVA una vez hechos los descuentos
   * @param itemsPedido
   */
  getTotalNeto(itemsPedido: MaterialPrVtas[]) {
    return (
      this.getTotalPedido(itemsPedido) -
      this.getDescuentoTotalCombos(itemsPedido) -
      this.getOtrosDescuentos(itemsPedido) -
      this.getRetorno(itemsPedido) -
      this.getDescuentoPorAcuerdos(itemsPedido) +
      this.getImpuestoInternoPedido(itemsPedido)
    );
  }

  getDescuentoPorAcuerdos(itemsPedido: MaterialPrVtas[]) {
    return itemsPedido.reduce(
      (sum: number, i): number =>
        sum + parseFloat(i.descuentoPorAcuerdos.toFixed(2)),
      0
    );
  }

  getRetorno(itemsPedido: MaterialPrVtas[]) {
    return itemsPedido.reduce(
      (sum: number, i): number => sum + parseFloat(i.retorno.toFixed(2)),
      0
    );
  }

  getPesoTotal(itemsPedido: MaterialPrVtas[]) {
    return itemsPedido.reduce(
      (sum: number, i): number => sum + this.getKilos(i),
      0
    );
  }

  cantidadEsValida(
    item: MaterialPrVtas,
    unidadMedida: string,
    cantidad: number
  ) {
    if (!this.materialesService.puedeCalcularDisplay(item.mATNR)) {
      return true; // ?????
    }

    let display = this.materialesService.calcularDisplay(item.mATNR);
    let unidades = this.materialesService.getCantidadEnUnidades(
      Object.assign({}, item, {
        UNIDAD: unidadMedida,
        CANTIDAD: cantidad,
      })
    );
    return unidades % display === 0;
  }

  getPalletsCompletos(item: MaterialPrVtas): number {
    let completos = Math.floor(
      this.getCajas(item) / this.materialesService.calcularPallet(item.mATNR)
    );
    return isNaN(completos) || !isFinite(completos) ? 0 : completos;
  }

  getCajasParaCompletar(item: MaterialPrVtas): number {
    let cajasPorPallet = this.materialesService.calcularPallet(item.mATNR);
    let cajasPedidas = this.getCajas(item);
    let restantes = cajasPedidas % cajasPorPallet;
    return restantes > 0 ? cajasPorPallet - restantes : 0;
  }

  getDescuentoTotalCombos(items: MaterialPrVtas[]): number {
    return items.reduce((sum, x) => sum + this.getDescuentoCombos(x), 0);
  }

  getOtrosDescuentos(items: MaterialPrVtas[]): number {
    return items
      .filter(
        (item) =>
          !["PR00", "COMB", "ZACC", "Z001", "Z002"].includes(item.COND_TYPE)
      )
      .reduce(
        (descuento, item) => descuento + this.getDescuentoAdicional(item),
        0
      );
  }

  protected obtenerCondicionesPedido(
    itemPedido: OrderItemsIn
  ): OrderConditionsIn[] {
    let { orderConditionsIns } = itemPedido;
    return !!orderConditionsIns ? orderConditionsIns : [];
  }

  protected hayCambioEnCondiciones(
    material: MaterialPrVtas,
    itemPedido: OrderItemsIn
  ) {
    // NO VA MAS PORQUE EL UNICO DESCUENTO QUE SE USA ES EL K007
    // if (!!material.COND_TYPE || !!material.CONDITION) {
    //     let condicion = material.COND_TYPE || material.CONDITION.COND_TYPE;
    //     return !this.obtenerCondicionesPedido(itemPedido)
    //         .map(cond => cond.COND_TYPE)
    //         .includes(condicion);
    // } else {
    //     // Si material de lista de precios no tiene condicion y pedido si,
    //     // entonces hubo cambio.
    //     return this.obtenerCondicionesPedido(itemPedido).length > 0;
    // }
    return false;
  }

  protected hayCambioEnValorDeCondiciones(
    material: MaterialPrVtas,
    itemPedido: OrderItemsIn
  ) {
    let condicion =
      material.COND_TYPE ||
      (material.CONDITION && material.CONDITION.COND_TYPE);
    let condicionPedido: OrderConditionsIn = this.obtenerCondicionesPedido(
      itemPedido
    )[condicion];
    return (
      !!condicion &&
      !!condicionPedido &&
      material.DESCUENTO !== condicionPedido.COND_VALUE
    );
  }

  hayCambiosEnItems(
    listaMateriales: MaterialPrVtas[],
    itemsPedido: OrderItemsIn[]
  ): boolean {
    const indexarItems = (items: OrderItemsIn[]) => {
      var indice: { [key: string]: OrderItemsIn } = {};
      items.forEach((i) => {
        if (i.importe > 0) {
          indice[i.MATERIAL] = i;
        }
      });
      return indice;
    };

    let pedido = indexarItems(itemsPedido);

    return (
      !!listaMateriales &&
      listaMateriales.some((item) => {
        const ITEM_PEDIDO = pedido[item.MATNR];

        let seAgregoUnItem = item.CANTIDAD > 0 && !ITEM_PEDIDO;
        let seQuitoUnItem = item.CANTIDAD === 0 && !!ITEM_PEDIDO;
        let cambioLaCantidad =
          !!ITEM_PEDIDO && item.CANTIDAD !== ITEM_PEDIDO.TARGET_QTY;
        let cambioLaUnidad =
          !!ITEM_PEDIDO && item.UNIDAD !== ITEM_PEDIDO.TARGET_QU;
        let cambioTipoDeCondicion =
          !!ITEM_PEDIDO && this.hayCambioEnCondiciones(item, ITEM_PEDIDO);
        let cambioValorDeCondicion =
          !!ITEM_PEDIDO &&
          this.hayCambioEnValorDeCondiciones(item, ITEM_PEDIDO);

        return (
          seAgregoUnItem ||
          seQuitoUnItem ||
          cambioLaCantidad ||
          cambioLaUnidad ||
          cambioTipoDeCondicion ||
          cambioValorDeCondicion
        );
      })
    );
  }
}
