import { formatDate } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, iif, Observable, of, Subscription } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, skip, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { ClientesService } from "src/app/clientes/clientes.service";
import { ClienteDatoComercial, Interlocutor } from "../clientes/models/Cliente.model";
import { AccionesManualesService } from "../condiciones-comerciales/acciones-manuales/acciones-manuales.service";
import { Condicion, CondicionVariante } from "../condiciones-comerciales/condiciones.models";
import { CondicionesService } from "../condiciones-comerciales/condiciones.service";
import { ListaPreciosService } from "../lista-precios/lista-precios.service";
import { ListaPrecios, MaterialPrVtas } from "../lista-precios/models/ListaPrecios.model";
import { DocumentsService } from "../utils/documents.service";
import { EventsService } from "../utils/events.service";
import { NotificationService } from "../utils/notifications/notification.service";
import { minDate } from "../utils/validators/min-date.validator";
import { File } from "../widgets/files/models/File.model";
import { TimelineEvent } from "../widgets/timeline/models/TimelineEvent.model";
import { IOrderHeaderObservacion, OrderHeaderIn, OrderHeaderObservacion, OrderItemsIn, OrderPartners } from "./models/Order.model";
import { CambiarMercaderiaBonificadaInput, PedidosService } from "./pedidos.service";

@Component({
    selector: "app-pedidos-detail",
    templateUrl: "./pedidos-detail.component.html",
    styleUrls: ["../../assets/ventas/pedidos/_pedidos.scss"],
    providers: [AccionesManualesService]
})
export class PedidosDetailComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    progressGuardar: boolean = false;
    progressConfirmar: boolean = false;
    vendedor: string;
    ultimaObservacion: IOrderHeaderObservacion;

    pedido_combos: Condicion[];

    readonly TODAY = formatDate(new Date(), "yyyy-MM-dd", "es-AR");

    motivoAnulacion = new FormControl("");

    pedido$: BehaviorSubject<OrderHeaderIn> = new BehaviorSubject(null);
    set pedido(value: OrderHeaderIn) {
        this.pedido$.next(value);
    }
    get pedido(): OrderHeaderIn {
        return this.pedido$.getValue();
    }

    itemsPendientes$ = this.pedido$.pipe(
        map((pedido) =>
            pedido.activo
                ? pedido.orderItems.filter(x => x.estadoLogistico.estado !== 'ANULADO' && x.estadoDespacho?.pendiente > 0)
                : []
        )
    );

    eventosPedido: TimelineEvent[] = [];
    documentos: File[] = [];
    searchTerm: string;

    set onEdit(value: boolean) {
        this.onEditControl.setValue(value);
    }

    get onEdit(): boolean {
        return this.onEditControl.value;
    }

    onEditControl = new FormControl(false);

    itemSourceEsPedido$ = new BehaviorSubject<boolean>(true);

    bloquearMientrasGuarda: boolean = false;

    isAnulacion: boolean = false;

    private listaMateriales$: BehaviorSubject<MaterialPrVtas[]> = new BehaviorSubject([]);

    set listaMateriales(value: MaterialPrVtas[]) {
        this.listaMateriales$.next(value);
    }
    get listaMateriales() {
        return this.listaMateriales$.getValue();
    }

    listaMaterialesInicial: MaterialPrVtas[] = [];

    loadingListaPrecios = new BehaviorSubject(false);

    selCliente: any;

    readonly itemSource$: Observable<Partial<MaterialPrVtas>[]> = combineLatest([
        this.itemSourceEsPedido$,
        this.pedido$,
        this.listaMateriales$,
    ]).pipe(
        map(([itemSourceEsPedido, pedido, listaMateriales]) =>
            itemSourceEsPedido
                ? pedido.orderItems.map((i) => this.service.orderItemsInToMaterialPrVtas(i, pedido))
                : // Muestro primero los items que tienen cantidad requerida (un posible drawback es que quedan ordenados por cantidad descendente)
                listaMateriales.sort((a, b) => b.CANTIDAD - a.CANTIDAD).map(item => {
                    // Necesito mantener la referencia al item original porque el componente lo modifica!
                    const itemPedido = pedido.orderItemsIns.find(itemPedido => itemPedido.MATERIAL === item.MATNR);

                    item.estadoDespacho = itemPedido?.estadoDespacho;
                    item.bonifica_canal = Boolean(itemPedido?.bonifica_canal);

                    return item;
                })
        ),
    );

    get selDatoCom() {
        return this.formPedido && this.formPedido.get("selDatoCom").value;
    }

    get fechaRequerida() {
        return this.formPedido && this.formPedido.get("fechaRequerida").value;
    }

    // selDirEntrega: string;

    formPedido = this.fb.group(
        {
            fechaRequerida: ["", [Validators.required, minDate(new Date())]],
            ordencompra: [null],
            selDatoCom: [null, [Validators.required], []],
            condicionPago: [null, [Validators.required]],
            accionesSecundariasManuales: [[]],
            INCOTERMS1: [null, [Validators.required]],
        },
        { disabled: true }
    );

    set condicionPago(value: string) {
        this.formPedido.patchValue({ condicionPago: value });
    }

    get condicionPago(): string {
        return this.formPedido.get("condicionPago").value;
    }

    //selectedCombos: number[] = [];
    selectedCombos: Condicion[] = [];
    private selectedCombosOriginal = [];

    /* get selectedCombos() {
          return this.formPedido.get('combos').value;
      }

      set selectedCombos(combos: Combo[]) {
          this.formPedido.get('combos').setValue(combos);
      } */

    private originales;

    get direccion_entrega(): OrderPartners {
        return this.interlocutor && {
            ITM_NUMBER: "000000",
            PARTN_NUMB: this.interlocutor.KUNN2,
            PARTN_ROLE: "WE",
        }
    }

    private interlocutor$: BehaviorSubject<Interlocutor> = new BehaviorSubject(
        null
    );
    set interlocutor(val: Interlocutor) {
        this.interlocutor$.next(val);
    }
    get interlocutor(): Interlocutor {
        return this.interlocutor$.getValue();
    }

    private entregaGratuitaOriginal: OrderHeaderIn;

    get userCanEdit$() {
        return this.service.userCanEdit$;
    }

    noHayItemsSinPrecio$ = this.pedido$.pipe(
      switchMap(pedido => this.listaMateriales$.pipe(
        // El primer valor de listaMateriales$ es vacío por defecto, antes de cargar. Lo esquivo:
        skip(1),
        filter(listaMateriales => !!pedido && !!listaMateriales),
        map(listaMateriales => {
            const materialesConPrecio = new Map(listaMateriales.map(x => [ x.MATNR, true ]));

            return pedido.orderItems.every(x => x.mATERIAL.MATKL !== 'PROD_TERM' || materialesConPrecio.has(x.MATERIAL));
        }),
        distinctUntilChanged(),
      ))
    );

    esPedidoEditable$ = this.pedido$.pipe(
        map(pedido => {
            if (pedido.estado > 2) {
                return false;
            }

            if (pedido.estado == 0) {
                return true;
            }

            const date = moment(pedido.DOC_DATE);
            const lastOfMonth = moment(`${date.format('YYYY')}-${date.format('MM')}-${date.daysInMonth()}`);

            return moment().isSameOrBefore(lastOfMonth);
        }),
    );

    esPedidoConfirmable$ = this.pedido$.pipe(
        map(pedido => pedido.estado === undefined || pedido.estado === 0),
    );

    puedeConfirmar$ = combineLatest([ this.esPedidoConfirmable$, this.noHayItemsSinPrecio$ ]).pipe(
        map(([ esPedidoConfirmable, noHayItemsSinPrecio ]) => esPedidoConfirmable && noHayItemsSinPrecio)
    );

    puedeEditar$ = this.userCanEdit$.pipe(
        switchMap(userCanEdit => ! userCanEdit ? of(false) : combineLatest([ this.esPedidoEditable$, this.noHayItemsSinPrecio$ ]).pipe(
            map(([ esPedidoEditable, noHayItemsSinPrecio ]) => esPedidoEditable && noHayItemsSinPrecio),
        )),
    );

    constructor(
        private route: ActivatedRoute,
        private service: PedidosService,
        private clientesApi: ClientesService,
        private lpService: ListaPreciosService,
        private router: Router,
        private fb: FormBuilder,
        private modalService: NgbModal,
        private condicionService: CondicionesService,
        private notiService: NotificationService,
        private documentsService: DocumentsService,
        private eventsService: EventsService,
        private accionesManualesService: AccionesManualesService
    ) { }

    async ngOnInit() {
        // this.formPedido.get('selDatoCom').disable();
        this.subscriptions.push(
            this.userCanEdit$.subscribe((can) => {
                if (!can) {
                    this.onEditControl.disable();
                }
            })
        );

        this.subscriptions.push(
            this.pedido$
                .pipe(filter((p) => ![null, undefined].includes(p)))
                .subscribe((pedido) => {
                    this.vendedor = pedido.pernrAndNombreVendedor;
                    this.selCliente = pedido.kUNNR;

                    var ilocutores = this.clientesApi.getInterlocutoresEntrega(
                        pedido.kUNNR
                    );

                    this.interlocutor = ilocutores.find(
                        x => x.KUNN2 === pedido.direccion_entrega?.PARTN_NUMB
                    );

                    var selDatoCom = pedido.datoComercial;

                    /*  var selCentroSuministros = selDatoCom.orgCentros
                                  .find((v) => v.WERKS === this.service.resolverCentroSuministro(pedido)); */
                    var fechaRequerida = formatDate(
                        pedido.REQ_DATE_H,
                        "yyyy-MM-dd",
                        "es-AR"
                    );

                    this.formPedido.reset({
                        selDatoCom,
                        // selCentroSuministros,
                        fechaRequerida,
                        ordencompra: pedido.PURCH_NO_C,
                        condicionPago: pedido.PMNTTRMS,
                        INCOTERMS1: pedido.INCOTERMS1,
                    });
                    //console.log(this.formPedido);
                    this.originales = this.formPedido.value;

                    pedido.condicionVarianteOrderHeaderIns.forEach((ohc) => {
                        ohc.condicionVariante.condicion.variante_id =
                            ohc.condicionVariante.id;
                        ohc.condicionVariante.condicion.acciones =
                            ohc.accionesSecundariasAplicadas;
                    });
                    this.pedido_combos = pedido.condicionVarianteOrderHeaderIns
                        ? pedido.condicionVarianteOrderHeaderIns.map(
                            (c) => c.condicionVariante.condicion
                        )
                        : [];
                    this.selectedCombosOriginal = this.pedido_combos || [];
                    this.cargarListaPrecios(selDatoCom);
                    this.cargarDocumentos();
                    this.cargarEventos();

                    if ([null, undefined].includes(pedido.ultimaObservacion)) {
                        pedido.ultimaObservacion = new OrderHeaderObservacion();
                    }

                    if (pedido.estado === 0) {
                        if (pedido.condicionVarianteOrderHeaderIns.length > 0) {
                            if (this.service.tieneCombosNoVerificados(pedido)) {
                                this.notiService.info(
                                    "Las condiciones comerciales requerirán de la Aprobación Comercial.", "",
                                );
                            } else {
                                this.notiService.info(
                                    "Condiciones comerciales verificadas completamente.", "",
                                );
                            }
                        }
                    }

                    if (pedido.accionesSecundariasManuales) {
                        this.accionesManualesService.setAcciones(pedido.accionesSecundariasManuales);
                    }

                    if (pedido.advertencias.length > 0) {
                        this.notiService.warning(
                            "<b>El pedido tiene las siguientes advertencias:</b><br />" +
                            pedido.advertencias.join("<br />")
                            , "Atención", false);
                    }

                    if (pedido.mensajes?.length > 0) {
                        pedido.mensajes.forEach(x => this.notiService.info(x, 'Información', true));
                    }
                })
        );

        this.subscriptions.push(
            this.accionesManualesService.acciones$.subscribe(acciones => {
                this.formPedido.patchValue({ accionesSecundariasManuales: acciones });
            })
        );

        this.subscriptions.push(
            this.onEditControl.valueChanges
                .pipe(
                    filter(() => !!this.pedido),
                    withLatestFrom(this.accionesManualesService.dirty$)
                )
                .subscribe(async ([onEdit, accionesManualesDirty]) => {
                    let saleDeEdicion = !onEdit;

                    let confirmaSalir = () =>
                        this.notiService.confirm(
                            "Si sale del modo de edición perderá los cambios que no haya guardado. ¿Está seguro de que desea continuar?",
                            "Confirmar"
                        );

                    if (saleDeEdicion && !this.bloquearMientrasGuarda && (this.hayCambiosSinGuardar() || accionesManualesDirty)) {
                        // Cambio el valor del control a "edicion" inmediatamente
                        this.onEditControl.setValue(true, { emitEvent: false });
                        if (this.isAnulacion || (await confirmaSalir())) {
                            // Si confirma salir, lo tengo que cambiar a "no edición"
                            this.onEditControl.setValue(false, { emitEvent: false });
                            this.formPedido.reset(this.originales);
                            this.selectedCombos = this.selectedCombosOriginal;
                            this.condicionService.reset(this.selectedCombos);
                            this.reiniciarListaMateriales();
                            this.accionesManualesService.cancel();
                            this.itemSourceEsPedido$.next(true);
                        }
                    } else {
                        this.reiniciarListaMateriales();
                        this.itemSourceEsPedido$.next(saleDeEdicion);
                    }
                })
        );

        this.subscriptions.push(
            this.condicionService.selectedCondiciones$.subscribe((combos) => {
                this.selectedCombos = [];
                combos.forEach((c) => {
                    this.selectedCombos.push(c);
                });
            })
        );

        const params = this.route.snapshot.paramMap;
        const idCliente = parseInt(params.get('cliente_id'));

        if (Number.isNaN(idCliente)) {
            this.router.navigate(['/clientes']);
            return;
        }

        const idPedido = parseInt(params.get('id'));

        if (Number.isNaN(idPedido)) {
            this.router.navigate(['/clientes', idCliente]);
            return;
        }

        const pedido = await this.service.getPedido(idPedido).toPromise();

        this.noHayItemsSinPrecio$.subscribe(noHayItemsSinPrecio => {
            if ( ! noHayItemsSinPrecio && pedido.estado === 0) {
                this.notiService.warning('El pedido tiene items sin precio vigente', 'Atención');
            }
        });

        this.pedido$.next(pedido);
    }

    cambiarMercaderiaBonificada(input: CambiarMercaderiaBonificadaInput) {
        this.service.cambiarMercaderiaBonificada(this.pedido.id, input).subscribe(pedido => {
            this.pedido$.next(pedido);
        })
    }

    async cargarListaPrecios(datoComercial: ClienteDatoComercial): Promise<void> {
        if (!datoComercial) {
            this.listaMateriales = [];
            this.condicionService.setCondiciones([]);
            return;
        }

        this.loadingListaPrecios.next(true);

        try
        {
            const [ precios, condiciones ] = await Promise.all([
                this.lpService
                    .getPrecios(datoComercial.id)
                    .toPromise(),
                this.lpService.getCondiciones(datoComercial.PLTYP, this.pedido.id, datoComercial.KUNNR).toPromise(),
            ]);

            this.listaMateriales = this.lpService.updateCantidades(
                precios,
                this.pedido.orderItemsIns.filter(x => x.importe > 0)
            );

            this.condicionService.setCondiciones(condiciones);
            this.condicionService.select(...this.pedido_combos);
        }
        finally
        {
            this.loadingListaPrecios.next(false);
        }
    }

    cargarEventos() {
        this.eventsService
            .fetch(this.pedido.id)
            .subscribe((x) => (this.eventosPedido = x));
    }

    cargarDocumentos() {
        this.documentsService
            .fetch(this.pedido.id)
            .subscribe((x) => (this.documentos = x));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.condicionService.cleanUp();
    }

    onSetCondicionesEntrega({ incoterms, interlocutor }) {

    }

    hayCambiosEnItems(): boolean {
        if (!this.pedido) {
            return false;
        }
        return this.lpService.hayCambiosEnItems(
            this.listaMateriales,
            this.pedido.orderItemsIns
        );
    }

    reiniciarListaMateriales() {
        this.listaMateriales = this.lpService.updateCantidades(
            this.listaMateriales,
            this.pedido.orderItemsIns
        );
    }

    hayCambiosEnCombos(): boolean {
        let seQuitaronCombos = this.selectedCombosOriginal.some(
            (combo) => !this.condicionService.isSelected(combo)
        );
        //let seAgregaronCombos = this.selectedCombos.some(combo => !this.selectedCombosOriginal.includes(combo));
        let seAgregaronCombos = this.selectedCombos.some(
            (combo) =>
                !this.selectedCombosOriginal.some(
                    (e) => e.id === combo.id && e.variante_id === combo.variante_id
                )
        );
        return seQuitaronCombos || seAgregaronCombos;
    }

    hayCambiosSinGuardar() {
        return (
            this.formPedido.dirty ||
            this.hayCambiosEnCombos() ||
            this.hayCambiosEnItems()
        );
    }

    async onConfirmarPedido() {
        return this.guardarPedido(true);
    }

    async onGuardarPedido() {
        return this.guardarPedido(false);
    }

    onAnularPedido($event) {
        this.modalService.open($event);
    }

    onAceptarAnulacion() {
        this.isAnulacion = true;
        this.service
            .anularPedido(this.pedido.id, this.motivoAnulacion.value)
            .subscribe(
                (pedido) => {
                    this.onEditControl.reset(false);
                    this.notiService.success(
                        "El pedido se anuló correctamente",
                        "Estado del Pedido",
                    );
                    this.pedido$.next(pedido as OrderHeaderIn);
                    this.modalService.dismissAll();
                },
                ({ error }) => {
                    if (error.message) {
                        this.notiService.error(error.message, "Error");
                    }
                },
                () => (this.isAnulacion = false)
            );
    }

    setProgress($event, value: boolean) {
        if ($event != true) {
            this.progressGuardar = value;
        } else {
            this.progressConfirmar = value;
        }
    }

    onAutorizarPedido($event) {
        this.subscriptions.push(
            this.service.resolverAutorizacionPedido(this.pedido.id, $event).subscribe(
                (pedido) => {
                    this.pedido$.next(pedido);
                    this.modalService.dismissAll();
                },
                ({ error }) => {
                    let errors = Object.entries(error.errors)
                        .map(([key, value]) => `${key}: ${value}`)
                        .join("\n");
                    this.notiService.error(`${error.message}:\n\n${errors}`, "Error");
                }
            )
        );
    }

    onCloseCombos($event) {
        if (this.hayCambiosEnCombos()) {
            this.notiService.info(
                "Para aplicar los cambios de selección debe guardar el pedido.",
                "Selección de Combos",
            );
        }
    }

    async guardarPedido(confirma: boolean) {
        this.setProgress(confirma, true);

        let pedido = await this.service.prepararPedido(
            Object.assign({}, this.formPedido.value, {
                fechaRequerida: this.formPedido.get("fechaRequerida"),
                listaMateriales: this.listaMateriales,
                direccion_entrega: this.direccion_entrega,
                confirmado: confirma,
            }),
            this.pedido
        );

        if (!pedido) {
            this.setProgress(confirma, false);
            return;
        }

        const esVarianteVigente = (x: CondicionVariante) => {
            const desde = moment(x.vigencia_desde).hour(0).minute(0).second(0).millisecond(0);

            // FIXME? No debería ser el último milisegundo del día?
            const hasta = moment(x.vigencia_hasta).hour(0).minute(0).second(0).millisecond(0);

            return moment().isBetween(desde, hasta.add(1, 'days'), undefined, '[)');
        };

        const esVarianteHabilitada = (x: CondicionVariante) =>
            x && x.habilitado && esVarianteVigente(x);

        const findVariante = (x: Condicion, id: number) => x.condicionVariantes?.find(y => y.id == id);

        const esCondicionHabilitada = (x: Condicion) => x.habilitado
            && esVarianteHabilitada(findVariante(x, x.variante_id));

        pedido.aplicar_combos = [];

        for (let c of this.selectedCombos) {
            if (pedido.estado < 1 && ! esCondicionHabilitada(c)) {
                const message = ''
                    + 'Hay Condiciones Comerciales no habilitadas o fuera de vigencia. '
                    + 'Deberá quitarlas para poder continuar';

                this.notiService.warning(message, 'Condiciones Comerciales');
                this.setProgress(confirma, false);
                return;
            }

            let combo_data: any = {
                id: c.id,
                variante_id: c.variante_id,
            };

            let variante = c.condicionVariantes.find((x) => x.id == c.variante_id);

            if (!variante.condicionVarianteAccionsecs) {
                pedido.aplicar_combos.push(combo_data);
                continue;
            }

            combo_data.acciones_secundarias = [];

            const warning = (msg: string) => this.notiService.warning(msg, 'Condiciones Comerciales');

            for (let accionSec of variante.condicionVarianteAccionsecs) {
                if (pedido.estado < 1 && accionSec.valor > 0 &&  !accionSec.habilitado) {
                    warning('Existen acciones secundarias no habilitadas. Debe quitarlas para continuar');
                    this.setProgress(confirma, false);
                    return;
                }

                const superaValorMaximo = accionSec.tieneMaximo()
                    && accionSec.valor > accionSec.dto_monto;

                if (superaValorMaximo) {
                    warning("Existen acciones secundarias con valor mayor al permitido");
                    this.setProgress(confirma, false);
                    return;
                }

                if (accionSec.valor < 0) {
                    warning("El valor de la acción secundaria debe ser igual o mayor a 0");
                    this.setProgress(confirma, false);
                    return;
                }

                if (accionSec.valor > 0 && !accionSec.tieneContenido()) {
                    warning("Debe ingresar un contenido para las acciones secundarias");
                    this.setProgress(confirma, false);
                    return;
                }

                combo_data.acciones_secundarias.push({
                    // order_header_in_id: pedido.id,
                    condicion_variante_id: variante.id,
                    accion_id: accionSec.accion_id,
                    valor: accionSec.valor,
                    contenido: accionSec.contenido,
                });
            }

            pedido.aplicar_combos.push(combo_data);
        }

        this.bloquearMientrasGuarda = true;

        try
        {
            const data = await this.service.updatePedido(pedido).toPromise();

            this.searchTerm = "";
            this.pedido = data;
            this.onEditControl.setValue(false);
            this.accionesManualesService.save();
            this.cargarEventos();

            if (confirma && data.mensajes && data.mensajes.length > 0) {
                let title = "Aprobación Comercial";

                if (data.wfl_status.includes("aprobcomN1")) {
                    title += " Nivel 1";
                } else if (data.wfl_status.includes("aprobcomN2")) {
                    title += " Nivel 2";
                } else if (data.wfl_status.includes("aprobcomN3")) {
                    title += " Nivel 3";
                }

                for (const mensaje of data.mensajes) {
                    this.notiService.info(mensaje, title);
                }
            }
        }
        catch (error: any)
        {
            let msg = error.error?.message
                ?? "Ha ocurrido un error al guardar el pedido";

            if (error.error?.errors) {
                const errors = Object.values(error.error.errors)
                    .map((value: any[]) => value
                        .map(x => String(x))
                        .join('\n')
                    )
                    .join('')

                msg = `${msg}\n${errors}`;
            }

            this.notiService.error(msg, "Error");

            console.log(error);
        }
        finally
        {
            this.bloquearMientrasGuarda = false;
            this.setProgress(confirma, false);
        }
    }

    goBack() {
        history.back();
    }
}
