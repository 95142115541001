import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CondicionesComercialesModule } from 'src/app/condiciones-comerciales/condiciones-comerciales.module';
import { AcuerdosModule } from '../acuerdos/acuerdos.module';
import { ListaPreciosModule } from '../lista-precios/lista-precios.module';
import { SharedModule } from '../shared.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { CondicionesEntregaComponent } from './condiciones-entrega/condiciones-entrega.component';
import { CondicionesPagoComponent } from './condiciones-entrega/condiciones-pago.component';
import { EntregaGratuitaComponent } from './entrega-gratuita/entrega-gratuita.component';
import { PedidosFooterComponent } from './footer/pedidos-footer.component';
import { PedidoHeaderComponent } from './header/pedido-header.component';
import { ItemsPendientesComponent } from './items-pendientes.component';
import { PedidosAprobacionComponent } from './list/pedidos-aprobacion.component';
import { PedidosVendedorComponent } from './list/pedidos-vendedor.component';
import { PedidosDetailComponent } from './pedidos-detail.component';
import { PedidosNewComponent } from './pedidos-new.component';
import { MercaderiaBonificadaComponent } from './mercaderia-bonificada/mercaderia-bonificada.component';
import { CambioMercaderiaBonificadaModalComponent } from './cambio-mercaderia-bonificada-modal/cambio-mercaderia-bonificada-modal.component';

@NgModule({
    declarations: [
        CambioMercaderiaBonificadaModalComponent,
        CondicionesEntregaComponent,
        CondicionesPagoComponent,
        ItemsPendientesComponent,
        PedidoHeaderComponent,
        PedidosAprobacionComponent,
        PedidosDetailComponent,
        PedidosFooterComponent,
        PedidosNewComponent,
        PedidosVendedorComponent,
        EntregaGratuitaComponent,
        MercaderiaBonificadaComponent,
    ],
    imports: [
        AcuerdosModule,
        CondicionesComercialesModule,
        ListaPreciosModule,
        MatSlideToggleModule,
        SharedModule,
        WidgetsModule,
    ],
    exports: [
        PedidosDetailComponent,
        PedidosNewComponent,
        PedidosAprobacionComponent,
        PedidosVendedorComponent
    ]
})
export class PedidosModule {}