export const environment = {
    production: false,
    API_BASE_URL: 'https://lhtrapi.devops.corsisa.com.ar/',
    DEFAULT_HTTP_OPTIONS: {
        withCredentials: true,
        setParams: {
            "XDEBUG_SESSION_START": "netbeans-xdebug"
        }
    },
    HOME_URL: 'https://portal-lhtr.devops.corsisa.com.ar',
    NXT_BASE_URL: 'https://nxt.corsisa.com.ar',
    NXT_BASE_DAV_PEDIDOS: '/remote.php/webdav/corsisa_dev/Lheritier/test_pedidos_portal',
    LOGISTICA_HREF: 'https://lhtr2.dev.corsisa.com.ar',
    LOGOPS_HREF: 'https://logops.test.corsisa.com.ar',
};
