import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, merge, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mapTo, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CatalogoFactoryService } from '../catalogos/factory.service';
import { MaterialesService } from '../materiales.service';
import { ListaPreciosService } from './lista-precios.service';
import { MaterialPrVtas } from './models/ListaPrecios.model';
import { OrderHeaderIn } from '../pedidos/models/Order.model';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-lista-materiales',
    templateUrl: './lista-materiales.component.html',
    styles: ['td, th { padding: 0.35rem 0.2rem; }'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListaMaterialesComponent implements OnInit {
    private subscriptions: Subscription[] = [];

    private readonly itemsSource$: BehaviorSubject<MaterialPrVtas[]> = new BehaviorSubject([]);

    readonly conditionTypes$ = this.cf.getConditionType().list().pipe(
        take(1),
        map((conds) => conds.filter(i => i.habilitado))
    );

    private readonly edit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    @Input() set edit(val: boolean) {
        this.edit$.next(val);
    }

    get edit() {
        return this.edit$.getValue();
    }

    @Input() set items(value: MaterialPrVtas[]) {
        let items = value;

        if (this.itemsSource$.value.length === 0) {
            // Es la primera carga; son los items del pedido guardado
            items = !!items && items.map(item => Object.assign(item, { 
                AJUSTE: this.materialesService.getCantidadEnUnidades(item, item.CANTIDAD || 0) 
            }));
        }

        this.itemsSource$.next(items);
    }

    @Input() pesoMinimo: number = 0;

    private searchTerm$ = new BehaviorSubject<string>('');

    @Input() set searchTerm (value: string) {
        this.searchTerm$.next(value);
    }

    get searchTerm () {
        return this.searchTerm$.getValue();
    }

    @Input() currency: string;
    @Input() estado: number;

    get totalesColspan () {
        return 15 + (this.edit ? -1 : 0);
    }

    readonly isProduction = environment.production;

    readonly anItemHasChanged = new EventEmitter<void>();

    readonly update$ = merge(
        interval(1000).pipe(
            mapTo(true),
            takeUntil(this.anItemHasChanged)
        ),
        this.anItemHasChanged.pipe(
            debounceTime(200),
            mapTo(true)
        )
    );

    readonly itemsPedido$ = this.update$.pipe(
        delay(0),
        switchMap(() => this.itemsSource$.pipe(
            map(items => items.filter(x => x.CANTIDAD > 0))
        ))
    );

    readonly items$ = this.searchTerm$.pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        map(searchTerm => searchTerm.trim().toLowerCase()),
        tap(() => this.pageNumber$.next(1)),
        switchMap(searchTerm => this.edit$.pipe(
            switchMap(edit => this.itemsSource$.pipe(
                map(items => edit ? items : items.filter(x => x.CANTIDAD > 0))
            )),
            map(items => items.filter(i => {
                var descripcion = i.mATNR.MAKTX;
                var nroMaterial = i.MATNR;

                return descripcion.toLowerCase().includes(searchTerm)
                    || nroMaterial.toLowerCase().includes(searchTerm);
            }))
        ))
    );

    readonly pageSize$ = new BehaviorSubject<number>(10);
    readonly pageNumber$ = new BehaviorSubject<number>(1);

    readonly page$ = this.items$.pipe(
        switchMap(items => combineLatest([this.pageNumber$, this.pageSize$]).pipe(
            map(([ page, pageSize ]) => {
                const firstElement = (page - 1) * pageSize;
                const lastElement = ((page - 1) * pageSize) + pageSize;
    
                return items.slice(firstElement, lastElement);
            })
        ))
    );

    readonly totalElements$ = this.items$.pipe(map(x => x?.length || 0));

    readonly totalPedido$ = this.itemsPedido$.pipe(
        map((items) => this.service.getTotalPedido(items))
    );

    readonly IVAPedido$ = this.itemsPedido$.pipe(
        map((items) => this.service.getIVAPedido(items))
    );

    readonly impuestoInternoPedido$ = this.itemsPedido$.pipe(
        map(items => this.service.getImpuestoInternoPedido(items))
    );

    readonly descuentoPorAcuerdos$ = this.itemsPedido$.pipe(
        map(items => this.service.getDescuentoPorAcuerdos(items))
    );

    readonly retorno$ = this.itemsPedido$.pipe(
        map(items => this.service.getRetorno(items))
    );

    readonly totalNeto$ = this.itemsPedido$.pipe(
        map((items) => this.service.getTotalNeto(items))
    );

    readonly totalMasIVA$ = combineLatest([this.totalNeto$, this.IVAPedido$])
        .pipe(
            map(([total, iva]) => total + iva)
        );

    readonly pesoTotal$ = this.itemsPedido$.pipe(
        map((items) => this.service.getPesoTotal(items))
    );

    readonly descuentoCombos$ = this.itemsPedido$.pipe(
        map((items) => this.service.getDescuentoTotalCombos(items))
    );

    readonly otrosDescuentos$ = this.itemsPedido$.pipe(
        map((items) => this.service.getOtrosDescuentos(items))
    );

    constructor(
        public service: ListaPreciosService, 
        public materialesService: MaterialesService, 
        private cf: CatalogoFactoryService) {}

    ngOnInit() {
        this.searchTerm = "";
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    
}
