import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CambioMercaderiaBonificadaModalComponent } from "../cambio-mercaderia-bonificada-modal/cambio-mercaderia-bonificada-modal.component";
import { CambioMercaderiaBonificada, OrderItemsIn } from "../models/Order.model";
import { CambiarMercaderiaBonificadaInput } from "../pedidos.service";

@Component({
    selector: 'app-pedido-mercaderia-bonificada',
    templateUrl: './mercaderia-bonificada.component.html',
})
export class MercaderiaBonificadaComponent {
    readonly _cambios = new Map<string, CambioMercaderiaBonificada>();
    
    readonly trackById = (ix: number, e: { id: number }) => e.id;

    @Input() items: Array<OrderItemsIn & { opcionesCambio: Array<{ MATNR: string; MAKTX: string }> }> = [];

    @Input() set cambios(value: Array<CambioMercaderiaBonificada>) {
        this._cambios.clear();
        
        for (const v of value) {
            this._cambios.set(v.reemplazo.MATNR, v);
        }
    };

    get cambios() {
        return Array.from(this._cambios.values());
    }

    @Input() readonly: boolean = false;

    @Output('cambiar') cambiarEvent = new EventEmitter<CambiarMercaderiaBonificadaInput>();

    constructor(private readonly ngbModal: NgbModal) {

    }

    async cambiar(item: OrderItemsIn & { opcionesCambio: Array<{ MATNR: string; MAKTX: string }> }) {
        const modalRef = this.ngbModal.open(CambioMercaderiaBonificadaModalComponent, {
            backdrop: 'static',
            size: 'lg',
        });

        const component = modalRef.componentInstance as CambioMercaderiaBonificadaModalComponent;

        const productoOriginal = this._cambios.get(item.MATERIAL)?.original ?? item.mATERIAL;

        const cajasOriginal = this.items
            .filter(x => x.item_originante_id === item.item_originante_id)
            .map(x => x.TARGET_QTY)
            .reduce((x, total) => total + x, 0);

        component.inicializar({
            original: {
                producto: productoOriginal,
                cajas: cajasOriginal,
            },
            reemplazo: {
                producto: item.mATERIAL,
                cajas: item.TARGET_QTY,
            },
            productos: item.opcionesCambio,
        });

        try {
            const result = await modalRef.result.then(({ reemplazo }) => ({
                original: productoOriginal.MATNR,
                actual: item.MATERIAL,
                nuevo: reemplazo.producto.MATNR as string,
                cantidad: reemplazo.cajas as number,
            }));

            this.cambiarEvent.emit(result);
        } catch (e: unknown) {
            if (e instanceof Error) {
                throw e;
            }
        }
    }
}