<ng-container *ngIf="items$ | async as items">
  <ng-template #cargando>
    <div class="p-3"><p>Cargando...</p></div>
  </ng-template>
  <ng-container *ngIf="conditionTypes$|async as conditionTypes; else cargando">
    <ng-container *ngIf="conditionTypes.length; else cargando">
      <div class="form-row row">
        <div class="col-4 p-2">
          <div class="form-group form-inline mb-2">
            Buscar:
            <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="searchTerm" />
          </div>
        </div>
        <div class="col-8">
          <!-- <page-selector [collectionSize]="items.length"></page-selector> -->
          <span class="float-right" *ngIf="totalElements$|async as totalElements">
            <ngb-pagination
                [collectionSize]="totalElements"
                [page]="pageNumber$|async"
                (pageChange)="pageNumber$.next($event)"
                [maxSize]="10"
                [pageSize]="pageSize$|async"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              >
              </ngb-pagination>
            </span>
        </div>
      </div>
      <div class="table-responsive">
        <table
          class="align-middle text-truncate mb-2 table table-borderless table-hover table-pedidos"
          style="overflow: visible"
        >
          <thead>
            <tr>
              <th class="text-left"></th>
              <th class="text-right">Código</th>
              <th class="text-left">Descripción</th>
              <th class="text-center" *ngIf="!edit">Despacho</th>
              <th class="text-right" *ngIf="!edit">Pend. CJ</th>
              <th class="text-right">UN/CJ</th>
              <th *ngIf="edit" class="text-center"></th>
              <!-- cjs / pal -->
              <th class="text-right">Cantidad</th>
              <th class="text-right">UN</th>
              <th class="text-right">Peso</th>
              <th class="text-right">$/Un.</th>
              <th class="text-right">$/Caja</th>
              <!-- <th class="text-left">Tipo Dto</th> -->
              <th class="text-right">Dto Adic</th>
              <th class="text-right">$ Dto Adic</th>
              <th class="text-right">Dto Combo</th>
              <th class="text-right">$ Dto Combo</th>
              <th class="text-right">Importe Neto</th>
              <th *ngIf="edit" class="text-center">Stock</th>
              <th *ngIf="!isProduction" class="text-center">Bonif. Canal</th>
            </tr>
          </thead>
          <tbody>
            <tr
              app-lista-materiales-item
              *ngFor="let item of (page$ | async)"
              [estado]="estado ? estado : 0"
              [edit]="edit"
              [currency]="currency"
              [tiposDescuento]="conditionTypes"
              [item]="item"
              (change)="anItemHasChanged.emit()">
            </tr>
          </tbody>
          <tbody>
            <tr class="mt-4 pt-4" style="border-top: solid lightgrey 1px"></tr>
            <tr>
              <td [attr.colspan]="7 + (edit ? -1 : 0)"></td>
              <th class="text-left" scope="row">Peso Total</th>
              <td class="text-right">
                <ng-container *ngIf="pesoTotal$ | async as pesoTotal; else sinPeso">
                  <span [ngClass]="{ 'text-danger': pesoTotal < pesoMinimo }">{{ pesoTotal | number: "1.0-2" }} kg</span>
                </ng-container>
                <ng-template #sinPeso>
                  <span class="text-danger">{{ 0 | number: "1.0-2" }}</span>
                </ng-template>
              </td>
              <th class="text-right" colspan="6" scope="row">Total Bruto</th>
              <td class="text-right">
                {{ totalPedido$ | async | currency: currency }}
              </td>
              <td *ngIf="!isProduction"></td>
            </tr>
            <tr *ngIf="descuentoCombos$ | async as descuentoCombos">
              <th class="text-right" scope="row" [attr.colspan]="totalesColspan">
                Descuento por Combos
              </th>
              <td class="text-right">
                - {{ descuentoCombos | currency: currency }}
              </td>
            </tr>
            <tr *ngIf="descuentoPorAcuerdos$ | async as descuentoPorAcuerdos">
              <th class="text-right" scope="row" [attr.colspan]="totalesColspan">
                Descuento por Acuerdos
              </th>
              <td class="text-right">
                -{{ descuentoPorAcuerdos | currency: currency }}
              </td>
            </tr>
            <tr *ngIf="retorno$ | async as retorno">
              <th class="text-right" scope="row" [attr.colspan]="totalesColspan">
                Retorno
              </th>
              <td class="text-right">-{{ retorno | currency: currency }}</td>
            </tr>
            <tr *ngIf="otrosDescuentos$ | async as otrosDescuentos">
              <th class="text-right" scope="row" [attr.colspan]="totalesColspan">
                Descuento Adicional
              </th>
              <td class="text-right">
                -{{ otrosDescuentos | currency: currency }}
              </td>
            </tr>
            <tr>
              <th class="text-right" scope="row" [attr.colspan]="totalesColspan">
                TOTAL (S/IVA)
              </th>
              <th class="text-right" scope="row">
                {{ totalNeto$ | async | currency: currency }}
              </th>
              <td *ngIf="!isProduction"></td>
            </tr>
            <tr>
              <th class="text-right" scope="row" [attr.colspan]="totalesColspan">
                IVA 21%
              </th>
              <td class="text-right">
                {{ IVAPedido$ | async | currency: currency }}
              </td>
              <td *ngIf="!isProduction"></td>
            </tr>
            <ng-container
              *ngIf="impuestoInternoPedido$ | async as impuestoInternoPedido"
            >
              <tr *ngIf="impuestoInternoPedido > 0">
                <th
                  class="text-right"
                  scope="row"
                  [attr.colspan]="totalesColspan"
                >
                  Imp. Interno
                </th>
                <td class="text-right">
                  {{ impuestoInternoPedido | currency: currency }}
                </td>
                <td *ngIf="!isProduction"></td>
              </tr>
            </ng-container>
            <tr>
              <th class="text-right" scope="row" [attr.colspan]="totalesColspan">
                TOTAL
              </th>
              <th class="text-right" scope="row">
                {{ totalMasIVA$ | async | currency: currency }}
              </th>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
