<form [formGroup]="form" (ngSubmit)="guardar()">
    <div class="modal-header">
        <h5>Cambiar Mercadería Bonificada</h5>
        <button type="button" class="close" aria-label="Cerrar" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-row" formGroupName="original">
            <div class="col-9 form-group">
                <label for="cmb-producto-original" class="form-label">Producto</label>
                <ng-select labelForId="cmb-producto-original" [compareWith]="compareWith" [items]="[]" [clearable]="false" formControlName="producto">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        {{ item.MATNR | ltrim:'0' }} - {{ item.MAKTX }}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-3 form-group">
                <label for="cmb-cajas-original" class="form-label">Cajas</label>
                <input type="number" id="cmb-cajas-original" class="form-control" min="0" formControlName="cajas" />
            </div>
        </div>
        <div class="form-row" formGroupName="reemplazo">
            <div class="col-9 form-group">
                <label for="cmb-producto-reempl" class="form-label">Reemplazo</label>
                <ng-select labelForId="cmb-producto-reempl" [compareWith]="compareWith" [items]="productos" [clearable]="false" formControlName="producto">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        {{ item.MATNR | ltrim:'0' }} - {{ item.MAKTX }}
                    </ng-template>
                </ng-select>
                <div class="small text-danger form-text" *ngIf="(producto.touched || producto.dirty) && (producto.errors ?? form.errors) as err">
                    <ng-container *ngIf="err['required']">Debe seleccionar producto</ng-container>
                    <ng-container *ngIf="err['distinct']">No puede seleccionar el mismo producto para el reemplazo. Si desea anular el cambio ingrese cajas 0</ng-container>
                </div>
            </div>
            <div class="col-3 form-group">
                <label for="cmb-cajas-reempl" class="form-label">Cajas</label>
                <input type="number" id="cmb-cajas-reempl" class="form-control" min="0" formControlName="cajas" />
                <div class="small text-danger form-text" *ngIf="(cajas.touched || cajas.dirty) && cajas.errors as err">
                    <ng-container *ngIf="err['required']">Debe ingresar cajas</ng-container>
                    <ng-container *ngIf="err['min'] as e">El mínimo es de {{ e.min }} caja{{ e.min !== 1 ? 's':'' }}</ng-container>
                    <ng-container *ngIf="err['max'] as e">El máximo es de {{ e.max }} caja{{ e.max !== 1 ? 's':'' }}</ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancelar</button>
        <button type="submit" class="btn btn-success">Guardar</button>
    </div>
</form>